<template>
  <div class="w-10/12">
    <div style="height: 100%" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>
    <div v-else class="mt-6">
      <div class="w-full">
        <p class="font-semibold mb-7 inline-block">Education & Certification</p>
        <span
          v-if="!cannotEdit"
          class="float-right cursor-pointer text-flame text-base font-semibold"
        >
          <span @click="handleClick" v-if="!edit">
            <icon icon-name="icon-edit" size="xs" class="inline-block" />
            <span class="pl-2">Edit</span>
          </span>
          <span @click="handleSave" v-else>
            <icon icon-name="save" size="xs" class="inline-block" />
            <span class="pl-2">Save</span>
          </span>
        </span>
      </div>

      <div class="flex flex-col gap-2 mt-1 border p-4 rounded-lg">
        <ValidationObserver v-slot="{ handleSubmit }">
          <div class="flex mt-2">
            <span class="text-sm font-semibold flex flex-grow"
              >Educational Credentials</span
            >
            <span
              class="flex gap-1 cursor-pointer"
              @click="handleSubmit(addCredentials)"
            >
              <icon
                icon-name="icon-plus"
                class-name="text-carrotOrange"
                size="xms"
              />
              <span class="text-sm text-flame font-semibold">Add another</span>
            </span>
          </div>
          <divider />
          <div
            class="grid grid-cols-12 gap-6 text-darkPurple"
            style="margin-top: 1.125rem"
            v-for="(data, index) in educationalInfo"
            :key="index"
          >
            <div class="col-span-4">
              <c-text
                placeholder="--Enter--"
                variant="w-full"
                label="Qualification"
                :rules="['required']"
                :disabled="!edit"
                v-model="data.qualification"
              />
            </div>
            <div class="col-span-4">
              <c-text
                placeholder="--Enter--"
                variant="w-full"
                label="Institution"
                :rules="['required']"
                :disabled="!edit"
                v-model="data.institution"
              />
            </div>
            <div class="col-span-3" style="min-width: 284px">
              <c-text
                placeholder="--Enter--"
                variant="w-full h-10"
                label="Year Of Award"
                :rules="['required']"
                :disabled="!edit"
                v-model="data.yearOfAward"
              />
            </div>
            <div class="ml-11 mt-5">
              <span
                class="flex flex-col cursor-pointer justify-center my-4"
                @click="deleteEducationalInfo(index, data)"
              >
                <Icon
                  icon-name="icon-trash"
                  size="xs"
                  class-name="text-desire"
                />
              </span>
            </div>
          </div>
        </ValidationObserver>
      </div>

      <divider class="my-5" />

      <div class="flex flex-col gap-2 border p-4 rounded-lg">
        <ValidationObserver v-slot="{ handleSubmit }">
          <div class="flex mt-2">
            <span class="text-sm font-semibold flex flex-grow"
              >Professional Certifications</span
            >
            <span
              class="flex gap-1 cursor-pointer"
              @click="handleSubmit(addCertifications)"
            >
              <icon
                icon-name="icon-plus"
                class-name="text-carrotOrange"
                size="xms"
              />
              <span class="text-sm text-flame font-semibold">Add another</span>
            </span>
          </div>
          <divider />
          <div
            class="grid grid-cols-12 gap-6 text-darkPurple"
            style="margin-top: 1.125rem"
            v-for="(data, index) in certificationInfo"
            :key="index"
          >
            <div class="col-span-4">
              <c-text
                placeholder="--Enter--"
                variant="w-full"
                label="Qualification"
                :rules="['required']"
                :disabled="!edit"
                v-model="data.qualification"
              />
            </div>
            <div class="col-span-4">
              <c-text
                placeholder="--Enter--"
                variant="w-full"
                label="Institution"
                :rules="['required']"
                :disabled="!edit"
                v-model="data.institution"
              />
            </div>
            <div class="col-span-3" style="min-width: 284px">
              <c-text
                placeholder="--Enter--"
                variant="w-full h-10"
                label="Year Of Award"
                :disabled="!edit"
                :rules="['required']"
                v-model="data.yearOfAward"
              />
            </div>
            <div class="ml-11 mt-5">
              <span
                class="flex flex-col cursor-pointer justify-center my-4"
                @click="deleteCertificationInfo(index, data)"
              >
                <Icon
                  icon-name="icon-trash"
                  size="xs"
                  class-name="text-desire"
                />
              </span>
            </div>
          </div>
        </ValidationObserver>
      </div>
    </div>

    <Modal v-if="dialogDel">
      <Card class="p-5 flex flex-col max-w-md">
        <div class>
          <p class="font-bold ml-4 my-5 text-center">
            Are you sure you want to delete this Record?
          </p>
          <div class="flex">
            <Button class="text-white" @click="deleteQualification(dataId);"
              :background="appearance.buttonColor || defaultBTNColor"
            >
              Yes
            </Button>
            <Button
              background-color="none"
              class="text-dynamicBackBtn border border-solid border-dynamicBackBtn ml-4"
              @click="dialogDel = false"
            >
              Cancel
            </Button>
          </div>
        </div>
      </Card>
    </Modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { ValidationObserver } from "vee-validate";
import CText from "@scelloo/cloudenly-ui/src/components/text";
import Divider from "@/components/divider";
import Button from "@/components/Button";
import Modal from "@/components/Modal";
import Card from "@/components/Card";

export default {
  name: "EmployeeCertificate",
  components: {
    ValidationObserver,
    CText,
    Divider,
    Modal,
    Card,
    Button,
  },
  props: {
    cannotEdit: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      defaultBTNColor: (state) => state.orgAppearance.defaultBtnColor,
      appearance: (state) => state.orgAppearance.orgAppearanceData,
    }),
  },

  data() {
    return {
      loading: true,
      dialogDel: false,
      edit: false,
      educationalInfo: [],
      certificationInfo: [],
      dataId: ''
    };
  },

  methods: {
    deleteEducationalInfo(position, data) {
      if (Object.values(data).every((value) => !value)) {
        this.educationalInfo.splice(position, 1);
      } else {
        this.dataId = data.id;
        this.dialogDel = true;
      }
    },

    deleteCertificationInfo(position, data) {
      if (Object.values(data).every((value) => !value)) {
        this.certificationInfo.splice(position, 1);
      } else {
        this.dataId = data.id;
        this.dialogDel = true;
      }
    },

    deleteQualification(id) {
        this.$_deleteEducationQualification(id, this.$route.params.id)
          .then((response) => {
            this.$toasted.success(response.data.message, { duration: 3000 });
            this.loading = true;
            this.dialogDel = false;
            this.getQualifications();
          })
          .catch((error) => {
            this.$toasted.error(error.message, { duration: 3000 });
          });
    },

    async handleClick() {
      this.edit = !this.edit;
    },

    handleSave() {
      const certificationInfoCheck = this.checkIsEmpty(this.certificationInfo);
      const educationalInfoCheck = this.checkIsEmpty(this.educationalInfo);

      const payload = {
        educationalInfo: educationalInfoCheck
          ? educationalInfoCheck.map(({ id, ...rest }) => rest)
          : null,
        certificationInfo: certificationInfoCheck
          ? certificationInfoCheck.map(({ id, ...rest }) => rest)
          : null,
      };

      this.$_createEmployeeCertifications(payload, this.$route.params.id)
        .then((response) => {
          this.$toasted.success(response.data.message, { duration: 3000 });
          this.edit = false;
          this.getQualifications();
        })
        .catch((error) => {
          this.$toasted.error(error.message, { duration: 3000 });
        });
    },

    checkIsEmpty(arrData) {
      const data = arrData.every((obj) => {
        return Object.keys(obj).every((key) => obj[key] === "");
      });

      return data ? null : arrData;
    },

    addCertifications() {
      if (this.edit) {
        this.certificationInfo.push({
          institution: "",
          qualification: "",
          yearOfAward: "",
        });
      }
    },

    addCredentials() {
      if (this.edit) {
        this.educationalInfo.push({
          institution: "",
          qualification: "",
          yearOfAward: "",
        });
      }
    },

    getQualifications() {
      this.certificationInfo = [];
      this.educationalInfo = [];

      this.$_employeeCertifications(this.$route.params.id).then((response) => {
        if (response.data.certificationInfo.length === 0) {
          this.certificationInfo.push({
            institution: "",
            qualification: "",
            yearOfAward: "",
          });
        } else {
          response.data.certificationInfo.forEach((data) => {
            this.certificationInfo.push({
              id: data.id,
              institution: data.institution,
              qualification: data.qualification,
              yearOfAward: data.yearOfAward,
            });
          });
        }

        if (response.data.educationalInfo.length === 0) {
          this.educationalInfo.push({
            institution: "",
            qualification: "",
            yearOfAward: "",
          });
        } else {
          response.data.educationalInfo.forEach((data) => {
            this.educationalInfo.push({
              id: data.id,
              institution: data.institution,
              qualification: data.qualification,
              yearOfAward: data.yearOfAward,
            });
          });
        }

        this.loading = false;
      });
    },
  },

  mounted() {
    this.getQualifications();
  },
};
</script>
